import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

const getCategorySubjectType = (
  prefix: string,
  hasLocalNews: boolean,
) => (
  hasLocalNews
    ? upperFirst(`${camelCase(prefix)}Category`)
    : 'Category'
);

export default getCategorySubjectType;
